body {
    background-color: #f1f1f1 !important;
}

.contact-top {
    background-image: url("https://cdn.fanzine.com/assets/images/fanzine/contact-us-bg.jpg");
    height: 350px;
    width: 100%;
}

.contact-bottom {
    background-image: url("https://cdn.fanzine.com/assets/images/fanzine/contact-form-bg.jpg");
    min-height: 350px;
    width: 100%;
    margin-bottom: -3rem !important;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    display: block;
    background: url(https://cdn-icons-png.flaticon.com/512/60/60995.png) no-repeat;
    width: 65%;
    height: 10%;
    border: 0 !important;
}

input[type="date"]:focus-visible {
    outline: 0 !important;
}


input[type="date"]::-webkit-calendar-picker-indicator {

    content: url('https://cdn-icons-png.flaticon.com/512/60/60995.png');

}


.center-items {
    justify-content: center !important;
    align-items: center !important;
}

.heading-bold {
    font-weight: 500 !important;
}


.calendar-item {
    border: 0px;
    cursor: pointer;
}

.calendar-item-day {
    font-weight: 700;
}

.calendar-item:hover {
    font-weight: 700;
}

.bg-black {
    background-color: #000000 !important;
}

.bg-white {
    background-color: #fff !important;
}

.bg-grey {
    background-color: #c2c2c2 !important;
}

.bg-background {
    background-color: #f1f1f1 !important;
}

.boder-radius-15px {
    border-radius: 15px !important;
}


.DatePicker_dateDayItem__ZFl6u {
    border-radius: 8px !important;
    height: 50px !important;
}

.border-radius-10px {
    border-radius: 10px !important;
}


.font-36 {
    font-size: 36px;
}

.font-40 {
    font-size: 40px;
}

.font-48 {
    font-size: 48px;
}

.font-nft-overlay-title {
    font-size: 90px;
}

.nav-mega-menu-fanzine {
    width: 50em !important;
    padding-bottom: 1em;
    max-height: 75vh;
    overflow: auto;
}

.nav-content-fanzine {
    width: 100% !important;
}

.fanzine-color {
    color: #24722f !important;
}


.news-overlay {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
    /*background-color: rgba(0, 0, 0, 0.6);*/
    /*background-image: linear-gradient(to top, rgba(0, 0, 0, 0.6), rgba(59, 59, 59, 0.5), rgba(119, 119, 119, 0.4), rgba(185, 185, 185, 0.3), rgba(0, 0, 0, 0));*/
    /*height: 40%;*/
    margin-top: auto;
    margin-bottom: 0;
}

.video-overlay {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%), url('../../assets/images/play_button.png') no-repeat content-box center;
    /*background-color: rgba(0, 0, 0, 0.6);*/
    /*background-image: linear-gradient(to top, rgba(0, 0, 0, 0.6), rgba(59, 59, 59, 0.5), rgba(119, 119, 119, 0.4), rgba(185, 185, 185, 0.3), rgba(0, 0, 0, 0));*/
    /*height: 40%;*/
    background-size: contain;
    margin-top: auto;
    margin-bottom: 0;
}

.video-overlay-slider {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%),url('../../assets/images/play_button.png') no-repeat content-box center;
    background-repeat: no-repeat;
    background-position: center;
    /*background-color: rgba(0, 0, 0, 0.6);*/
    /*background-image: linear-gradient(to top, rgba(0, 0, 0, 0.6), rgba(59, 59, 59, 0.5), rgba(119, 119, 119, 0.4), rgba(185, 185, 185, 0.3), rgba(0, 0, 0, 0));*/
    /*height: 40%;*/
    background-size: none;
    margin-top: auto;
    margin-bottom: 0;
}


.news-text {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    min-height: 60px;
    max-height: 60px;
}

.match-card {
    height: 250px;
    color: #fff;
    padding: 15px 31px 18px 20px;
    /*border: solid 1px #fff;*/
    /*border-radius: 15px;*/
    background-size: cover !important;
    /*justify-content: center;*/
}

.slider-mobile-container {
    display: flex;
    /*overflow-x: scroll;*/
    width: 100%;
}

.overflow-scroll-x {
    overflow-x: scroll !important;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
.slider-mobile-container {
    scrollbar-width: auto;
    scrollbar-color: #c2c2c2 #e9e9e9;
}

/* Chrome, Edge, and Safari */
.slider-mobile-container::-webkit-scrollbar {
    width: 500px !important;
    height: 10px;
}

.slider-mobile-container::-webkit-scrollbar-track {
    background: transparent;
}

.slider-mobile-container::-webkit-scrollbar-thumb {
    background-color: #c2c2c2;
    border-radius: 10px;
    border: 1px solid #e9e9e9;
}

.slider-mobile-match-card {
    flex-shrink: 0;
    width: 475px;
    height: 100%;
    margin-left: 10px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.responsive-navbar-nav-fanzine .dropdown-menu {
    border: none !important;
    left: 0 !important;
    width: 100% !important;
    background: transparent !important;
}

.bm-burger-button {
    display: none !important;
}

.navbar-toggler {
    /*position: absolute;*/
    left: 0;
    top: 0;
    z-index: 1001;
    width: 100%;
    height: 100%;
    float: right !important;
    /*margin: 0;*/
    /*padding: 0;*/
    border: none !important;
    font-size: 0;
    background: transparent;
    cursor: pointer;
}

.bm-overlay {
    position: fixed;
    z-index: 1000;
    width: 100% !important;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    opacity: 1;
    /*transform: translate3d(100%, 0px, 0px);*/
    transition: opacity 0.3s ease 0s, transform 0s ease 0.3s;
    top: 0 !important;
    left: 0 !important;
    display: none;
}

.iframe-content {
    top: 25px;
    overflow: hidden;
    bottom: 0;
    width: 100%;
    position: fixed;
}

.iframe-content > iframe {
    height: 100%;
    width: 100%;
}

.iframe-content-nft {
    top: 0;
    overflow: hidden;
    bottom: 0;
    width: 100%;
    position: fixed;
}

.iframe-content-nft > iframe {
    height: 100%;
    width: 100%;
}

.mt-n10 {
    margin-top: -10px !important;
}

.fanzine-slider-arrow:before {
    color: rgba(0, 0, 0, 0.75) !important;
    font-size: 75px !important;
    font-weight: 100 !important;
    line-height: 0 !important;
}

.text-decoration-underline-fanzine {
    text-decoration: none;
}

.text-decoration-underline-fanzine:active, .text-decoration-underline-fanzine.active {
    text-decoration: underline;
    text-underline-offset: 18px;
    text-decoration-thickness: 7px;
}

.font-italic{
    font-style: italic !important;
}

.shadow
{
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
}

@media only screen and (max-width: 600px) {

    .slider-mobile-match-card {
        flex-shrink: 0;
        width: 350px;
        height: 100%;
        margin-left: 10px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }

    .match-card {
        height: 200px;
        color: #fff;
        padding: 15px 31px 18px 20px;
        /*border: solid 1px #fff;*/
        /*border-radius: 15px;*/
        background-size: cover !important;
        /*justify-content: center;*/
    }

    .pt-n150 {
        margin-top: -150px !important;
    }

    .responsive-navbar-nav-fanzine .dropdown-menu {
        border: none !important;
        left: 0 !important;
        width: 100% !important;
        background: transparent !important;
    }

    .fanzine-nav-responsive.show {
        background-color: darkgrey !important;
        padding: 0 !important;
    }

    .fanzine-nav-responsive .nav-link, .fanzine-nav-responsive .nav-link:hover {
        color: #000000;
    }

    .border-bottom-5 {
        height: 50px !important;
        border-bottom: 5px solid #dee2e6 !important;
    }

    .font-nft-overlay-title {
        font-size: 50px;
    }

}

