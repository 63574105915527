.bg-red {
    background-color: #e20001;
}

.rounded-top-custom {
    border-top-left-radius: 25px !important;
    border-top-right-radius: 25px !important
}

.rounded-bottom-custom {
    border-bottom-left-radius: 25px !important;
    border-bottom-right-radius: 25px !important
}

.text-light-black {
    color: #2a2a2a;
}

.social-list {
    height: 550px;
    overflow: auto;
}

/*.social-list li { background-color: #f6f6f6; }*/
/*.social-list li:nth-child(odd) { background-color: #f6f6f6; }*/

.icon-height {
    height: 50px;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
.social-list {
    scrollbar-width: auto;
    scrollbar-color: #c2c2c2 #e9e9e9;
}

/* Chrome, Edge, and Safari */
.social-list::-webkit-scrollbar {
    width: 10px !important;
}

.social-list::-webkit-scrollbar-track {
    background: #e9e9e9;
}

.social-list::-webkit-scrollbar-thumb {
    background-color: #c2c2c2;
    border-radius: 10px;
    border: 1px solid #e9e9e9;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
.social-list-long {
    scrollbar-width: 10px;
    scrollbar-color: #c2c2c2 #e9e9e9;
}

/* Chrome, Edge, and Safari */
.social-list-long::-webkit-scrollbar {
    width: 10px !important;
}

.social-list-long::-webkit-scrollbar-track {
    background: #e9e9e9;
}

.social-list-long::-webkit-scrollbar-thumb {
    background-color: #c2c2c2;
    border-radius: 10px;
    border: 1px solid #e9e9e9;
}