@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
/*@import url('https://db.onlinewebfonts.com/c/650e75c44483de69a226a69a55c25e82?family=Acumin+Pro');*/
@import '../../../node_modules/react-modal-video/scss/modal-video.scss';
@import url('http://fonts.cdnfonts.com/css/azo-sans');
@import url('https://use.typekit.net/meq5uek.css');



@font-face {
    font-family: "SF Pro Display Bold";
    src: url("../fonts/SFProDisplay-Bold.eot");
    src: url("../fonts/SFProDisplay-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/SFProDisplay-Bold.woff2") format("woff2"),
    url("../fonts/SFProDisplay-Bold.woff") format("woff"),
        /*url("../fonts/SFProDisplay-Bold.ttf") format("truetype"),*/ url("../fonts/SFProDisplay-Bold.svg#SF Pro Display") format("svg");
}

@font-face {
    font-family: "SF Pro Display";
    src: url("../fonts/SFProDisplay-Regular.eot");
    src: url("../fonts/SFProDisplay-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/SFProDisplay-Regular.woff2") format("woff2"),
    url("../fonts/SFProDisplay-Regular.woff") format("woff"),
        /*url("../fonts/SFProDisplay-Regular.ttf") format("truetype"),*/ url("../fonts/SFProDisplay-Regular.svg#SF Pro Display") format("svg");
}

@font-face {
    font-family: "Bebas Neue";
    src: url("../fonts/BebasNeueBold.eot");
    src: url("../fonts/BebasNeueBold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/BebasNeueBold.woff2") format("woff2"),
    url("../fonts/BebasNeueBold.woff") format("woff"),
        /*url("../fonts/BebasNeueBold.ttf") format("truetype"),*/ url("../fonts/BebasNeueBold.svg#Bebas Neue") format("svg");
}

@font-face {
    font-family: "Roboto Condensed";
    src: url("../fonts/RobotoCondensed-Bold.ttf");
}

* {
    font-family: "SF Pro Display", serif;
}

.bebasFont {
    font-family: 'Bebas Neue', cursive;
}

.acuminFont {
    font-family: "Acumin Pro", serif !important;
}

.robotoFont {
    font-family: "Roboto", sans-serif !important;
}

.sfProDisplayFont {
    font-family: "SF Pro Display", serif !important;
}

.sfProDisplayFontBold {
    font-family: "SF Pro Display Bold", serif !important;
    font-weight: 800 !important;
}

.robotoCondensedBold {
    font-family: "Roboto Condensed", sans-serif !important;
    font-weight: 800 !important;
}

body {
    padding-top: 70px;
    /*max-width: 1024px;*/
    /*margin-left: 448px;*/
}

.container > .customContainer {
    /*max-width: 960px;*/
    /*margin-left: 448px;*/
}

.text-yellow {
    color: #ffe647;
}

.text-blue {
    color: #003ea5;
}

.font-weight-100 {
    font-weight: 100 !important;
}

.font-weight-200 {
    font-weight: 200 !important;
}

.font-weight-300 {
    font-weight: 300 !important;
}

.font-weight-400 {
    font-weight: 400 !important;
}

.font-weight-500 {
    font-weight: 500 !important;
}

.font-weight-600 {
    font-weight: 600 !important;
}

.font-weight-700 {
    font-weight: 700 !important;
}

.font-weight-800 {
    font-weight: 800 !important;
}

.topBar {
    width: 100%;
    height: 25px;
}

.bg-dark-red {
    background-color: #bc0404;
}

.bg-red {
    background-color: #e20001;
}

.bg-blue {
    background-color: blue;
}

.bg-dark-grey {
    background-color: #dcdcdc;
}

.bg-grey {
    background-color: #eeeeee;
}

.bg-light-grey {
    background-color: #373737;
}

.bg-light-dark {
    background-color: #1e1e1e;
}

.bg-light-dark:hover > div {
    /*background-color: rgba(30, 30, 30, 0.5) !important;*/
    color: #1e1e1e !important;
}

.bg-dark:hover > a {
    /*background-color: rgba(30, 30, 30, 0.5) !important;*/
    color: #1e1e1e !important;
}

.bg-grey-input, .bg-grey-input:hover, .bg-grey-input:focus, .bg-grey-input::selection {
    background-color: #eeeeee !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
}

.bg-sale {
    background-color: #ffaa18;
}

.bg-yellow {
    background-color: #ffe647;
}

.bg-blue {
    background-color: #003ea5;
}

.nav-bar {
    width: 100%;
    /*padding: 0 15% 0 15%;*/
    height: 70px;
}

.text-white {
    color: #ffffff !important;
}

.billboardBanner {
    height: 250px;
    width: 100%;
    background-color: #9c9c9c;
    color: #fff;
}

.pushDownBanner {
    height: calc(250px + 90px);
    width: 100%;
    background-color: #9c9c9c;
    color: #fff;
}

.single-mpu-ad {
    height: 300px;
    background-color: #9c9c9c;
    color: #fff;
}

.double-mpu-ad {
    height: 600px;
    background-color: #9c9c9c;
    color: #fff;
}

.sticky-mpu-ad {
    height: 100vh;
    background-color: #9c9c9c;
    color: #fff;
}

.image-carousel img {
    border: solid 1px #fff;
    border-radius: 15px;
}

.rounded-custom-1 {
    border-radius: 50px;
}

.rounded-custom {
    border-radius: 15px !important;
}

.rounded-top-custom-1 {
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important
}

.rounded-top-custom {
    border-top-left-radius: 25px !important;
    border-top-right-radius: 25px !important
}

.rounded-bottom-custom {
    border-bottom-left-radius: 25px !important;
    border-bottom-right-radius: 25px !important
}

.text-light-black {
    color: #2a2a2a;
}

.text-light-gray {
    color: #bdbdbd;
}

.text-gray {
    color: #9b9b9b;
}

.text-gray-custom {
    color: #767676;
}

.text-mini-cart {
    color: #757273;
}

.placeholder-gray::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #a8a8a8;
    opacity: 1; /* Firefox */
}

.placeholder-gray:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #a8a8a8;
}

.placeholder-gray::-ms-input-placeholder { /* Microsoft Edge */
    color: #a8a8a8;
}

.border-gray {
    border-color: #e9e9e9;
}

.shop-category-overlay {
    background-color: rgba(0, 0, 0, 0.3) !important;
}

.fanatics-teams-overlay {
    background-color: rgba(0, 0, 0, 0.3);
}

.nav-content {
    width: 35%
}

.text-header {
    font-size: 25px;
}

.mt-see-more {
    margin-top: 1rem;
}

.podcast-main {
    height: 500px;
    width: fit-content;
}

.podcast-small {
    height: 200px;
    width: fit-content;
}

/*.nav-item.dropdown:hover .dropdown-menu {
    display: block;
}*/

.nav-mega-menu {
    width: 30em;
    padding-bottom: 1em;
    max-height: 75vh;
    overflow: auto;
}

.nav-mega-menu .dropdown-item.active, .nav-mega-menu .dropdown-item:active {
    background-color: transparent;
}

/* Chrome, Edge, and Safari */
.nav-mega-menu::-webkit-scrollbar {
    width: 16px;
}

.nav-mega-menu::-webkit-scrollbar-track {
    background: #e9e9e9;
}

.nav-mega-menu::-webkit-scrollbar-thumb {
    background-color: #c2c2c2;
    border-radius: 10px;
    border: 3px solid #e9e9e9;
}

.dropdown-menu {
    width: max-content !important;
    right: auto !important;
    left: 0 !important;
    margin-top: 10px !important;
    padding-top: 0 !important;
}

.no-caret-dropdown::after, .no-caret-dropdown a::after {
    display: none !important;
}

.text-news-carousel {
    font-size: 50px;
}

.news-overlay {
    background-color: rgba(0, 0, 0, 0.3);
}

.shop-top-banner {
    width: 100%;
    height: 144px;
}

.shadow-nav {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.shadow-nav-dark {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.5);
}

.w-fit-content {
    width: fit-content !important;
}

.sticky-top {
    position: sticky;
    top: 100px;
    z-index: 1020;
}

.btn-xl {
    padding: 1.5rem 1rem;
    font-size: 1.65rem;
    border-radius: 0.3rem;
}

#size-guide-table {
    margin-top: -25px;
    z-index: 99999;
}

.bg-green {
    background-color: #7fde58;
}

.bg-dark-green {
    background-color: rgb(14, 125, 69);
}

.w-40 {
    width: 40%;
}

.font-8 {
    font-size: 8px;
}

.font-9 {
    font-size: 9px;
}

.font-10 {
    font-size: 10px;
}

.font-11 {
    font-size: 11px;
}

.font-12 {
    font-size: 12px;
}

.font-13 {
    font-size: 13px;
}

.font-14 {
    font-size: 14px;
}

.font-16 {
    font-size: 16px;
}

.font-18 {
    font-size: 18px;
}

.font-20 {
    font-size: 20px;
}

.font-22 {
    font-size: 22px;
}

.font-24 {
    font-size: 24px;
}

.font-26 {
    font-size: 26px;
}

.font-28 {
    font-size: 28px;
}

.font-30 {
    font-size: 30px;
}

.font-32 {
    font-size: 32px;
}

.font-34 {
    font-size: 34px;
}

.font-xxl {
    font-size: xx-large;
}

.mt-n1 {
    margin-top: -0.25rem !important;
}

.mt-n2 {
    margin-top: -0.50rem !important;
}

.mt-n3 {
    margin-top: -0.75rem !important;
}

.mt-n4 {
    margin-top: -1rem !important;
}

.mt-n5 {
    margin-top: -1.25rem !important;
}

.promo-code-redeem-btn,
.promo-code-redeem-btn:hover {
    width: 169px;
    height: 46px;
    margin: 0 0 0 55px;
    padding: 13px 48px 12px 53px;
    background-color: #000;
    color: #ffffff;
    line-height: 1.17px;
}

.promo-code-redeem-btn-mobile,
.promo-code-redeem-btn-mobile:hover {
    width: 130px;
    height: 33px;
    margin: 0 0 0 55px;
    background-color: #000;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 !important;
}

.promo-input-mobile,
.promo-input-mobile:focus {
    height: 33px !important;
    border-radius: 0 !important;
}


.remove-control::-webkit-outer-spin-button,
.remove-control::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.remove-control {
    -moz-appearance: textfield; /* Firefox */
}


.cart:hover > .mini-cart {
    display: block;
}

.mini-cart {
    position: absolute;
    z-index: 9;
    right: 5%;
    left: auto;
    display: none;
}

.loader {
    height: 100%;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: rgba(255, 255, 255, 0.7);
}

.opaque-loader {
    height: 100%;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: rgb(255, 255, 255);
}

.item-loader {
    height: 100%;
    /*position: absolute;*/
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: rgba(255, 255, 255, 0.7);
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none !important;
    box-shadow: none !important;
}

select:focus,
select:active,
select:focus {
    outline: none !important;
    box-shadow: none !important;
}

button:focus,
button:active,
button:focus {
    outline: none !important;
    box-shadow: none !important;
}

.navbar-toggler-icon:focus {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transform-origin: 10% 10%;
    -ms-transform-origin: 10% 10%;
    transform-origin: 10% 10%;
}

.w-fill {
    width: -webkit-fill-available !important;
}

.w-35 {
    width: 35% !important;
}

.continue-shopping-btn {
    color: #757273 !important;
    border-radius: 0 !important;
}

.color-circle {
    border-radius: 50%;
    display: inline-block;
    margin: 5px;
    border: solid #121211 1px;
    cursor: pointer;
}


.color-circle .tooltip {
    visibility: hidden;
    width: 120px;
    background-color: rgba(255, 255, 255);
    color: #000;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    /* Position the tooltip */
    position: absolute;
    z-index: 999;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
}

.divider {
    border-top: 2px dashed #949090;
}

.color-circle:hover .tooltip {
    visibility: visible;
}

.dropdown-item.active, .dropdown-item:active {
    color: #767676 !important;
    background-color: transparent !important;
}

.dropdown-item:hover {
    color: #767676 !important;
    background-color: #ffffff !important;
}

@import "responsive/stylesLaptop.css" screen and (max-width: 1024px);
@import "responsive/stylesTablet.css" screen and (max-width: 768px);
@import "responsive/stylesMobile.css" screen and (max-width: 600px);
