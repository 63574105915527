/*gunners*/
.text-gunners {
    color: #e20001 !important;
}
.font-gunners {
    color: #ffffff !important;
}
.bg-gunners {
    background-color: #e20001 !important;
}
.bg-gunners-dark {
    background-color: #bc0404 !important;
}

.bg-gunners-hover-color:hover {
    background-color: transparent !important;
    color: #e20001 !important;
}

.bg-gunners-hover-color:hover a {
    background-color: transparent !important;
    color: #e20001 !important;
}

.bg-gunners-before:before {
    color: #e20001 !important;
}

/*cfc-blues*/
.text-cfc-blues {
    color: #203b79 !important;
}
.font-cfc-blues {
    color: #ffffff !important;
}
.bg-cfc-blues {
    background-color: #203b79 !important;
}
.bg-cfc-blues-dark {
    background-color: #193370 !important;
}

.bg-cfc-blues-hover-color:hover {
    background-color: transparent !important;
    color: #203b79 !important;
}

.bg-cfc-blues-hover-color:hover a {
    background-color: transparent !important;
    color: #203b79 !important;
}

.bg-cfc-blues-before:before {
    color: #203b79 !important;
}

/*celtic1967*/
.text-celtic1967 {
    color: #00965A !important;
}
.font-celtic1967 {
    color: #ffffff !important;
}
.bg-celtic1967 {
    background-color: #00965A !important;
}
.bg-celtic1967-dark {
    background-color: #015433 !important;
}

.bg-celtic1967-hover-color:hover {
    background-color: transparent !important;
    color: #00965A !important;
}

.bg-celtic1967-hover-color:hover a {
    background-color: transparent !important;
    color: #00965A !important;
}

.bg-celtic1967-before:before {
    color: #00965A !important;
}

/*theyellows*/
.text-theyellows {
    color: #00A650 !important;
}
.font-theyellows {
    color: #00A650 !important;
}
.bg-theyellows {
    background-color: #FFE800 !important;
}
.bg-theyellows-dark {
    background-color: #00A650 !important;
}

.bg-theyellows-hover-color:hover {
    background-color: transparent !important;
    color: #FFE800 !important;
}

.bg-theyellows-hover-color:hover a {
    background-color: transparent !important;
    color: #FFE800 !important;
}

.bg-theyellows-before:before {
    color: #00A650 !important;
}

/*villatillidie*/
.text-villatillidie {
    color: #670E36 !important;
}
.font-villatillidie {
    color: #ffffff !important;
}
.bg-villatillidie {
    background-color: #670E36 !important;
}
.bg-villatillidie-dark {
    background-color: #58062B !important;
}

.bg-villatillidie-hover-color:hover {
    background-color: transparent !important;
    color: #670E36 !important;
}

.bg-villatillidie-hover-color:hover a {
    background-color: transparent !important;
    color: #670E36 !important;
}

.bg-villatillidie-before:before {
    color: #670E36 !important;
}

/*coywhites*/
.text-coywhites {
    color: #171716 !important;
}
.font-coywhites {
    color: #ffffff !important;
}
.bg-coywhites {
    background-color: #171716 !important;
}
.bg-coywhites-dark {
    background-color: #121211 !important;
}

.bg-coywhites-hover-color:hover {
    background-color: transparent !important;
    color: #171716 !important;
}

.bg-coywhites-hover-color:hover a {
    background-color: transparent !important;
    color: #171716 !important;
}

.bg-coywhites-before:before {
    color: #171716 !important;
}

/*coyirons*/
.text-coyirons {
    color: #7A263A !important;
}
.font-coyirons {
    color: #ffffff !important;
}
.bg-coyirons {
    background-color: #7A263A !important;
}
.bg-coyirons-dark {
    background-color: #71162C !important;
}

.bg-coyirons-hover-color:hover {
    background-color: transparent !important;
    color: #7A263A !important;
}

.bg-coyirons-hover-color:hover a {
    background-color: transparent !important;
    color: #7A263A !important;
}

.bg-coyirons-before:before {
    color: #7A263A !important;
}

/*watp*/
.text-watp {
    color: #034694 !important;
}
.font-watp {
    color: #ffffff !important;
}
.bg-watp {
    background-color: #034694 !important;
}
.bg-watp-dark {
    background-color: #003A7F !important;
}

.bg-watp-hover-color:hover {
    background-color: transparent !important;
    color: #034694 !important;
}

.bg-watp-hover-color:hover a {
    background-color: transparent !important;
    color: #034694 !important;
}

.bg-watp-before:before {
    color: #034694 !important;
}

/*lufcmot*/
.text-lufcmot {
    color: #005B9E !important;
}
.font-lufcmot {
    color: #ffffff !important;
}
.bg-lufcmot {
    background-color: #005B9E !important;
}
.bg-lufcmot-dark {
    background-color: #004D85 !important;
}

.bg-lufcmot-hover-color:hover {
    background-color: transparent !important;
    color: #005B9E !important;
}

.bg-lufcmot-hover-color:hover a {
    background-color: transparent !important;
    color: #005B9E !important;
}

.bg-lufcmot-before:before {
    color: #005B9E !important;
}

/*the-toffees*/
.text-the-toffees {
    color: #0032A1 !important;
}
.font-the-toffees {
    color: #ffffff !important;
}
.bg-the-toffees {
    background-color: #0032A1 !important;
}
.bg-the-toffees-dark {
    background-color: #012A84 !important;
}

.bg-the-toffees-hover-color:hover {
    background-color: transparent !important;
    color: #0032A1 !important;
}

.bg-the-toffees-hover-color:hover a {
    background-color: transparent !important;
    color: #0032A1 !important;
}

.bg-the-toffees-before:before {
    color: #0032A1 !important;
}

/*unitedreds*/
.text-unitedreds {
    color: #D01A0D !important;
}
.font-unitedreds {
    color: #ffffff !important;
}
.bg-unitedreds {
    background-color: #D01A0D !important;
}
.bg-unitedreds-dark {
    background-color: #AE0C00 !important;
}

.bg-unitedreds-hover-color:hover {
    background-color: transparent !important;
    color: #D01A0D !important;
}

.bg-unitedreds-hover-color:hover a {
    background-color: transparent !important;
    color: #D01A0D !important;
}

.bg-unitedreds-before:before {
    color: #D01A0D !important;
}

/*thekop*/
.text-thekop {
    color: #BD1423 !important;
}
.font-thekop {
    color: #ffffff !important;
}
.bg-thekop {
    background-color: #BD1423 !important;
}
.bg-thekop-dark {
    background-color: #AA0514 !important;
}

.bg-thekop-hover-color:hover {
    background-color: transparent !important;
    color: #BD1423 !important;
}

.bg-thekop-hover-color:hover a {
    background-color: transparent !important;
    color: #BD1423 !important;
}

.bg-thekop-before:before {
    color: #BD1423 !important;
}

/*toonarmy*/
.text-toonarmy {
    color: #171716 !important;
}
.font-toonarmy {
    color: #ffffff !important;
}
.bg-toonarmy {
    background-color: #171716 !important;
}
.bg-toonarmy-dark {
    background-color: #121211 !important;
}

.bg-toonarmy-hover-color:hover {
    background-color: transparent !important;
    color: #171716 !important;
}

.bg-toonarmy-hover-color:hover a {
    background-color: transparent !important;
    color: #171716 !important;
}

.bg-toonarmy-before:before {
    color: #171716 !important;
}

/*coys*/
.text-coys {
    color: #002156 !important;
}
.font-coys {
    color: #ffffff !important;
}
.bg-coys {
    background-color: #002156 !important;
}
.bg-coys-dark {
    background-color: #001A45 !important;
}

.bg-coys-hover-color:hover {
    background-color: transparent !important;
    color: #002156 !important;
}

.bg-coys-hover-color:hover a {
    background-color: transparent !important;
    color: #002156 !important;
}

.bg-coys-before:before {
    color: #002156 !important;
}

/*mancityzens*/
.text-mancityzens {
    color: #6CABDD !important;
}
.font-mancityzens {
    color: #ffffff !important;
}
.bg-mancityzens {
    background-color: #6CABDD !important;
}
.bg-mancityzens-dark {
    background-color: #5095CC !important;
}

.bg-mancityzens-hover-color:hover {
    background-color: transparent !important;
    color: #6CABDD !important;
}

.bg-mancityzens-hover-color:hover a {
    background-color: transparent !important;
    color: #6CABDD !important;
}

.bg-mancityzens-before:before {
    color: #6CABDD !important;
}

/*comeonleicester*/
.text-comeonleicester {
    color: #00448E !important;
}
.font-comeonleicester {
    color: #ffffff !important;
}
.bg-comeonleicester {
    background-color: #00448E !important;
}
.bg-comeonleicester-dark {
    background-color: #003B7C !important;
}

.bg-comeonleicester-hover-color:hover {
    background-color: transparent !important;
    color: #00448E !important;
}

.bg-comeonleicester-hover-color:hover a {
    background-color: transparent !important;
    color: #00448E !important;
}

.bg-comeonleicester-before:before {
    color: #00448E !important;
}

/*wearewolves*/
.text-wearewolves {
    color: #FDB913 !important;
}
.font-wearewolves {
    color: #000000 !important;
}
.bg-wearewolves {
    background-color: #FDB913 !important;
}
.bg-wearewolves-dark {
    background-color: #000000 !important;
}

.bg-wearewolves-hover-color:hover {
    background-color: transparent !important;
    color: #FDB913 !important;
}

.bg-wearewolves-hover-color:hover a {
    background-color: transparent !important;
    color: #FDB913 !important;
}

.bg-wearewolves-before:before {
    color: #FDB913 !important;
}

/*saints1885*/
.text-saints1885 {
    color: #D71920 !important;
}
.font-saints1885 {
    color: #ffffff !important;
}
.bg-saints1885 {
    background-color: #D71920 !important;
}
.bg-saints1885-dark {
    background-color: #BB0E15 !important;
}

.bg-saints1885-hover-color:hover {
    background-color: transparent !important;
    color: #D71920 !important;
}

.bg-saints1885-hover-color:hover a {
    background-color: transparent !important;
    color: #D71920 !important;
}

.bg-saints1885-before:before {
    color: #D71920 !important;
}

/*greasychipbutty*/
.text-greasychipbutty {
    color: #EE2737 !important;
}
.font-greasychipbutty {
    color: #ffffff !important;
}
.bg-greasychipbutty {
    background-color: #EE2737 !important;
}
.bg-greasychipbutty-dark {
    background-color: #D61D2C !important;
}

.bg-greasychipbutty-hover-color:hover {
    background-color: transparent !important;
    color: #EE2737 !important;
}

.bg-greasychipbutty-hover-color:hover a {
    background-color: transparent !important;
    color: #EE2737 !important;
}

.bg-greasychipbutty-before:before {
    color: #EE2737 !important;
}

/*palaceeagles*/
.text-palaceeagles {
    color: #1B458F !important;
}
.font-palaceeagles {
    color: #ffffff !important;
}
.bg-palaceeagles {
    background-color: #1B458F !important;
}
.bg-palaceeagles-dark {
    background-color: #BF181C !important;
}

.bg-palaceeagles-hover-color:hover {
    background-color: transparent !important;
    color: #1B458F !important;
}

.bg-palaceeagles-hover-color:hover a {
    background-color: transparent !important;
    color: #1B458F !important;
}

.bg-palaceeagles-before:before {
    color: #1B458F !important;
}

/*coyhorns*/
.text-coyhorns {
    color: #171716 !important;
}
.font-coyhorns {
    color: #ffffff !important;
}
.bg-coyhorns {
    background-color: #171716 !important;
}
.bg-coyhorns-dark {
    background-color: #121211 !important;
}

.bg-coyhorns-hover-color:hover {
    background-color: transparent !important;
    color: #171716 !important;
}

.bg-coyhorns-hover-color:hover a {
    background-color: transparent !important;
    color: #171716 !important;
}

.bg-coyhorns-before:before {
    color: #171716 !important;
}

/*citybluebirds*/
.text-citybluebirds {
    color: #003B96 !important;
}
.font-citybluebirds {
    color: #ffffff !important;
}
.bg-citybluebirds {
    background-color: #003B96 !important;
}
.bg-citybluebirds-dark {
    background-color: #0047B5 !important;
}

.bg-citybluebirds-hover-color:hover {
    background-color: transparent !important;
    color: #003B96 !important;
}

.bg-citybluebirds-hover-color:hover a {
    background-color: transparent !important;
    color: #003B96 !important;
}

.bg-citybluebirds-before:before {
    color: #003B96 !important;
}

/*redandblackarmy*/
.text-redandblackarmy {
    color: #CF0911 !important;
}
.font-redandblackarmy {
    color: #ffffff !important;
}
.bg-redandblackarmy {
    background-color: #CF0911 !important;
}
.bg-redandblackarmy-dark {
    background-color: #B70007 !important;
}

.bg-redandblackarmy-hover-color:hover {
    background-color: transparent !important;
    color: #CF0911 !important;
}

.bg-redandblackarmy-hover-color:hover a {
    background-color: transparent !important;
    color: #CF0911 !important;
}

.bg-redandblackarmy-before:before {
    color: #CF0911 !important;
}

/*the-clarets*/
.text-the-clarets {
    color: #731D31 !important;
}
.font-the-clarets {
    color: #ffffff !important;
}
.bg-the-clarets {
    background-color: #731D31 !important;
}
.bg-the-clarets-dark {
    background-color: #691528 !important;
}

.bg-the-clarets-hover-color:hover {
    background-color: transparent !important;
    color: #731D31 !important;
}

.bg-the-clarets-hover-color:hover a {
    background-color: transparent !important;
    color: #731D31 !important;
}

.bg-the-clarets-before:before {
    color: #731D31 !important;
}

/*the-terriers*/
.text-the-terriers {
    color: #0E63AD !important;
}
.font-the-terriers {
    color: #ffffff !important;
}
.bg-the-terriers {
    background-color: #0E63AD !important;
}
.bg-the-terriers-dark {
    background-color: #06569C !important;
}

.bg-the-terriers-hover-color:hover {
    background-color: transparent !important;
    color: #0E63AD !important;
}

.bg-the-terriers-hover-color:hover a {
    background-color: transparent !important;
    color: #0E63AD !important;
}

.bg-the-terriers-before:before {
    color: #0E63AD !important;
}

/*foresttilidie*/
.text-foresttilidie {
    color: #ED1B2D !important;
}
.font-foresttilidie {
    color: #ffffff !important;
}
.bg-foresttilidie {
    background-color: #ED1B2D !important;
}
.bg-foresttilidie-dark {
    background-color: #CF1021 !important;
}

.bg-foresttilidie-hover-color:hover {
    background-color: transparent !important;
    color: #ED1B2D !important;
}

.bg-foresttilidie-hover-color:hover a {
    background-color: transparent !important;
    color: #ED1B2D !important;
}

.bg-foresttilidie-before:before {
    color: #ED1B2D !important;
}

/*goonstoke*/
.text-goonstoke {
    color: #ED1B2D !important;
}
.font-goonstoke {
    color: #ffffff !important;
}
.bg-goonstoke {
    background-color: #ED1B2D !important;
}
.bg-goonstoke-dark {
    background-color: #CF1021 !important;
}

.bg-goonstoke-hover-color:hover {
    background-color: transparent !important;
    color: #ED1B2D !important;
}

.bg-goonstoke-hover-color:hover a {
    background-color: transparent !important;
    color: #ED1B2D !important;
}

.bg-goonstoke-before:before {
    color: #ED1B2D !important;
}

/*theteessiders*/
.text-theteessiders {
    color: #E30613 !important;
}
.font-theteessiders {
    color: #ffffff !important;
}
.bg-theteessiders {
    background-color: #E30613 !important;
}
.bg-theteessiders-dark {
    background-color: #CA020E !important;
}

.bg-theteessiders-hover-color:hover {
    background-color: transparent !important;
    color: #E30613 !important;
}

.bg-theteessiders-hover-color:hover a {
    background-color: transparent !important;
    color: #E30613 !important;
}

.bg-theteessiders-before:before {
    color: #E30613 !important;
}

/*roverstilidie*/
.text-roverstilidie {
    color: #004792 !important;
}
.font-roverstilidie {
    color: #ffffff !important;
}
.bg-roverstilidie {
    background-color: #004792 !important;
}
.bg-roverstilidie-dark {
    background-color: #013F81 !important;
}

.bg-roverstilidie-hover-color:hover {
    background-color: transparent !important;
    color: #004792 !important;
}

.bg-roverstilidie-hover-color:hover a {
    background-color: transparent !important;
    color: #004792 !important;
}

.bg-roverstilidie-before:before {
    color: #004792 !important;
}

/*boingboingbaggies*/
.text-boingboingbaggies {
    color: #122E66 !important;
}
.font-boingboingbaggies {
    color: #ffffff !important;
}
.bg-boingboingbaggies {
    background-color: #122E66 !important;
}
.bg-boingboingbaggies-dark {
    background-color: #0E2553 !important;
}

.bg-boingboingbaggies-hover-color:hover {
    background-color: transparent !important;
    color: #122E66 !important;
}

.bg-boingboingbaggies-hover-color:hover a {
    background-color: transparent !important;
    color: #122E66 !important;
}

.bg-boingboingbaggies-before:before {
    color: #122E66 !important;
}

/*bcfckro*/
.text-bcfckro {
    color: #1B3F8F !important;
}
.font-bcfckro {
    color: #ffffff !important;
}
.bg-bcfckro {
    background-color: #1B3F8F !important;
}
.bg-bcfckro-dark {
    background-color: #103381 !important;
}

.bg-bcfckro-hover-color:hover {
    background-color: transparent !important;
    color: #1B3F8F !important;
}

.bg-bcfckro-hover-color:hover a {
    background-color: transparent !important;
    color: #1B3F8F !important;
}

.bg-bcfckro-before:before {
    color: #1B3F8F !important;
}

/*comeonderby*/
.text-comeonderby {
    color: #171716 !important;
}
.font-comeonderby {
    color: #ffffff !important;
}
.bg-comeonderby {
    background-color: #171716 !important;
}
.bg-comeonderby-dark {
    background-color: #121211 !important;
}

.bg-comeonderby-hover-color:hover {
    background-color: transparent !important;
    color: #171716 !important;
}

.bg-comeonderby-hover-color:hover a {
    background-color: transparent !important;
    color: #171716 !important;
}

.bg-comeonderby-before:before {
    color: #171716 !important;
}

/*fanzinesports*/
.text-fanzinesports {
    color: #171716 !important;
}
.font-fanzinesports {
    color: #ffffff !important;
}
.bg-fanzinesports {
    background-color: #171716 !important;
}
.bg-fanzinesports-dark {
    background-color: #121211 !important;
}

.bg-fanzinesports-hover-color:hover {
    background-color: transparent !important;
    color: #171716 !important;
}

.bg-fanzinesports-hover-color:hover a {
    background-color: transparent !important;
    color: #171716 !important;
}

.bg-fanzinesports-before:before {
    color: #171716 !important;
}

/*fanzine*/
.text-fanzine {
    color: #000000 !important;
}
.font-fanzine {
    color: #74dbef !important;
}
.bg-fanzine {
    /*background-color: #1e1e1e !important;*/
    background-color: #000000 !important;
}
.bg-fanzine-dark {
    /*old*/
    /*background-color: #24722f !important;*/
    background-color: #74dbef !important;
    color: #000000 !important;
}

.bg-fanzine-hover-color:hover {
    background-color: transparent !important;
    color: #000000 !important;
}

.bg-fanzine-hover-color:hover a {
    background-color: transparent !important;
    color: #000000 !important;
}

.bg-fanzine-before:before {
    color: #74dbef !important;
}

.btn-fanzine {
    background-color: #74dbef !important;
}

/*weareswansea*/
.text-weareswansea {
    color: #171716 !important;
}
.font-weareswansea {
    color: #ffffff !important;
}
.bg-weareswansea {
    background-color: #171716 !important;
}
.bg-weareswansea-dark {
    background-color: #121211 !important;
}

.bg-weareswansea-hover-color:hover {
    background-color: transparent !important;
    color: #171716 !important;
}

.bg-weareswansea-hover-color:hover a {
    background-color: transparent !important;
    color: #171716 !important;
}

.bg-weareswansea-before:before {
    color: #171716 !important;
}

/*oneteaminbristol*/
.text-oneteaminbristol {
    color: #E30613 !important;
}
.font-oneteaminbristol {
    color: #ffffff !important;
}
.bg-oneteaminbristol {
    background-color: #E30613 !important;
}
.bg-oneteaminbristol-dark {
    background-color: #CA020E !important;
}

.bg-oneteaminbristol-hover-color:hover {
    background-color: transparent !important;
    color: #E30613 !important;
}

.bg-oneteaminbristol-hover-color:hover a {
    background-color: transparent !important;
    color: #E30613 !important;
}

.bg-oneteaminbristol-before:before {
    color: #E30613 !important;
}

/*coybees*/
.text-coybees {
    color: #E30613 !important;
}
.font-coybees {
    color: #ffffff !important;
}
.bg-coybees {
    background-color: #E30613 !important;
}
.bg-coybees-dark {
    background-color: #CA020E !important;
}

.bg-coybees-hover-color:hover {
    background-color: transparent !important;
    color: #E30613 !important;
}

.bg-coybees-hover-color:hover a {
    background-color: transparent !important;
    color: #E30613 !important;
}

.bg-coybees-before:before {
    color: #E30613 !important;
}

/*rokerroar*/
.text-rokerroar {
    color: #EB172B !important;
}
.font-rokerroar {
    color: #ffffff !important;
}
.bg-rokerroar {
    background-color: #EB172B !important;
}
.bg-rokerroar-dark {
    background-color: #D70F22 !important;
}

.bg-rokerroar-hover-color:hover {
    background-color: transparent !important;
    color: #EB172B !important;
}

.bg-rokerroar-hover-color:hover a {
    background-color: transparent !important;
    color: #EB172B !important;
}

.bg-rokerroar-before:before {
    color: #EB172B !important;
}

/*weareqpr*/
.text-weareqpr {
    color: #0047B5 !important;
}
.font-weareqpr {
    color: #ffffff !important;
}
.bg-weareqpr {
    background-color: #0047B5 !important;
}
.bg-weareqpr-dark {
    background-color: #003E9E !important;
}

.bg-weareqpr-hover-color:hover {
    background-color: transparent !important;
    color: #0047B5 !important;
}

.bg-weareqpr-hover-color:hover a {
    background-color: transparent !important;
    color: #0047B5 !important;
}

.bg-weareqpr-before:before {
    color: #0047B5 !important;
}

/*wednesdayites*/
.text-wednesdayites {
    color: #0047B5 !important;
}
.font-wednesdayites {
    color: #ffffff !important;
}
.bg-wednesdayites {
    background-color: #0047B5 !important;
}
.bg-wednesdayites-dark {
    background-color: #003E9E !important;
}

.bg-wednesdayites-hover-color:hover {
    background-color: transparent !important;
    color: #0047B5 !important;
}

.bg-wednesdayites-hover-color:hover a {
    background-color: transparent !important;
    color: #0047B5 !important;
}

.bg-wednesdayites-before:before {
    color: #0047B5 !important;
}

/*thetractorboys*/
.text-thetractorboys {
    color: #0033A0 !important;
}
.font-thetractorboys {
    color: #ffffff !important;
}
.bg-thetractorboys {
    background-color: #0033A0 !important;
}
.bg-thetractorboys-dark {
    background-color: #002B88 !important;
}

.bg-thetractorboys-hover-color:hover {
    background-color: transparent !important;
    color: #0033A0 !important;
}

.bg-thetractorboys-hover-color:hover a {
    background-color: transparent !important;
    color: #0033A0 !important;
}

.bg-thetractorboys-before:before {
    color: #0033A0 !important;
}

/*playuppompey*/
.text-playuppompey {
    color: #293F9C !important;
}
.font-playuppompey {
    color: #ffffff !important;
}
.bg-playuppompey {
    background-color: #293F9C !important;
}
.bg-playuppompey-dark {
    background-color: #1D338F !important;
}

.bg-playuppompey-hover-color:hover {
    background-color: transparent !important;
    color: #293F9C !important;
}

.bg-playuppompey-hover-color:hover a {
    background-color: transparent !important;
    color: #293F9C !important;
}

.bg-playuppompey-before:before {
    color: #293F9C !important;
}

/*bhaseagulls*/
.text-bhaseagulls {
    color: #0057B8 !important;
}
.font-bhaseagulls {
    color: #ffffff !important;
}
.bg-bhaseagulls {
    background-color: #0057B8 !important;
}
.bg-bhaseagulls-dark {
    background-color: #003e84 !important;
}

.bg-bhaseagulls-hover-color:hover {
    background-color: transparent !important;
    color: #0057B8 !important;
}

.bg-bhaseagulls-hover-color:hover a {
    background-color: transparent !important;
    color: #0057B8 !important;
}

.bg-bhaseagulls-before:before {
    color: #0057B8 !important;
}

/*cim-bom*/
.text-cim-bom {
    color: #A90432 !important;
}
.font-cim-bom {
    color: #ffffff !important;
}
.bg-cim-bom {
    background-color: #A90432 !important;
}
.bg-cim-bom-dark {
    background-color: #FD9800 !important;
}

.bg-cim-bom-hover-color:hover {
    background-color: transparent !important;
    color: #A90432 !important;
}

.bg-cim-bom-hover-color:hover a {
    background-color: transparent !important;
    color: #A90432 !important;
}

.bg-cim-bom-before:before {
    color: #A90432 !important;
}

/*lablaugrana*/
.text-lablaugrana {
    color: #004D98 !important;
}
.font-lablaugrana {
    color: #ffffff !important;
}
.bg-lablaugrana {
    background-color: #004D98 !important;
}
.bg-lablaugrana-dark {
    background-color: #A50044 !important;
}

.bg-lablaugrana-hover-color:hover {
    background-color: transparent !important;
    color: #004D98 !important;
}

.bg-lablaugrana-hover-color:hover a {
    background-color: transparent !important;
    color: #004D98 !important;
}

.bg-lablaugrana-before:before {
    color: #004D98 !important;
}

/*barca*/
.text-barca {
    color: #004D98 !important;
}
.font-barca {
    color: #ffffff !important;
}
.bg-barca {
    background-color: #004D98 !important;
}
.bg-barca-dark {
    background-color: #A50044 !important;
}

.bg-barca-hover-color:hover {
    background-color: transparent !important;
    color: #004D98 !important;
}

.bg-barca-hover-color:hover a {
    background-color: transparent !important;
    color: #004D98 !important;
}

.bg-barca-before:before {
    color: #004D98 !important;
}

/*forza-juve*/
.text-forza-juve {
    color: #171716 !important;
}
.font-forza-juve {
    color: #ffffff !important;
}
.bg-forza-juve {
    background-color: #171716 !important;
}
.bg-forza-juve-dark {
    background-color: #121211 !important;
}

.bg-forza-juve-hover-color:hover {
    background-color: transparent !important;
    color: #171716 !important;
}

.bg-forza-juve-hover-color:hover a {
    background-color: transparent !important;
    color: #171716 !important;
}

/*hala-madrid*/
.text-hala-madrid {
    color: #2B3441 !important;
}
.font-hala-madrid {
    color: #ffffff !important;
}
.bg-hala-madrid {
    background-color: #2B3441 !important;
}
.bg-hala-madrid-dark {
    background-color: #232B36 !important;
}

.bg-hala-madrid-hover-color:hover {
    background-color: transparent !important;
    color: #2B3441 !important;
}

.bg-hala-madrid-hover-color:hover a {
    background-color: transparent !important;
    color: #2B3441 !important;
}

/*die-bayern*/
.text-die-bayern {
    color: #D90B29 !important;
}
.font-die-bayern {
    color: #ffffff !important;
}
.bg-die-bayern {
    background-color: #D90B29 !important;
}
.bg-die-bayern-dark {
    background-color: #C4001D !important;
}

.bg-die-bayern-hover-color:hover {
    background-color: transparent !important;
    color: #D90B29 !important;
}

.bg-die-bayern-hover-color:hover a {
    background-color: transparent !important;
    color: #D90B29 !important;
}

/*allezparis*/
.text-allezparis {
    color: #182434 !important;
}
.font-allezparis {
    color: #ffffff !important;
}
.bg-allezparis {
    background-color: #182434 !important;
}
.bg-allezparis-dark {
    background-color: #131E2C !important;
}

.bg-allezparis-hover-color:hover {
    background-color: transparent !important;
    color: #182434 !important;
}

.bg-allezparis-hover-color:hover a {
    background-color: transparent !important;
    color: #182434 !important;
}

.bg-allezparis-before:before {
    color: #182434 !important;
}
