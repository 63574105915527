.bg-red {
    background-color: #e20001;
}

.rounded-top-custom {
    border-top-left-radius: 25px !important;
    border-top-right-radius: 25px !important
}

.rounded-bottom-custom {
    border-bottom-left-radius: 25px !important;
    border-bottom-right-radius: 25px !important
}

.text-light-black {
    color: #2a2a2a;
}

.standings-list li { background-color: #e9e9e9; }
.standings-list li:nth-child(odd) { background-color: #f6f6f6; }

.icon-height {
    height: 50px;
}