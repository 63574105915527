
body {
    overflow-x: hidden !important;
    width: 100% !important;
}

.nft-imgComponent {
    background-repeat: no-repeat;
    background-size: cover;
    width: 100% !important;
    height: fit-content;
}

.nft-utility {
    background: #1e1e1e !important;
    height: 100% !important;
    /*width: 100% !important;*/
}

.font-nft {
    font-size: 20px;
}

.font-nft-title {
    font-size: 60px;
}

.nft-team {
    background: #ffffff !important;
    height: 100%;
    width: 100% !important;
}

.nft-button {
    background: #1e1e1e !important;
}

.nft-fanzine-bg {
    background: #009245 !important;
    height: 100%;
    width: 100% !important;
}

.col-padding-25 {
    /*padding-right: calc(var(--bs-gutter-x) * .25) !important;*/
    /*padding-left: calc(var(--bs-gutter-x) * .25) !important;*/
}

.star {
    width: 120px !important;
    height: 120px !important;
    /*margin: 204px 676px 0 0 !important;*/
    object-fit: contain !important;
}

@media only screen and (max-width: 600px) {
    .star {
        width: 80px !important;
        height: 65px !important;
    }

    .nft-imgComponent {
        height: 100% !important;
    }

}

.azo-sans {
    font-family: 'Azo Sans', sans-serif !important;
}

.azo-sans-bold {
    font-family: 'Azo Sans', sans-serif !important;
    font-weight: bolder !important;
}

.overlay-nft-utility {
    /*background-image: linear-gradient(to bottom, rgba(13, 13, 13, 0.3) 34%, rgba(12, 12, 12, 0.8));*/
}

/*::-webkit-scrollbar  {*/
/*    height: 0px;              !* height of horizontal scrollbar ← You're missing this *!*/
/*    width: 4px;               !* width of vertical scrollbar *!*/
/*    border: 1px solid #d5d5d5;*/
/*}*/

.modal-header {
    border-bottom: 0px !important;
}

.modal-content {
    border-radius: 30px !important;
}


.nft-border-transform {
    margin-top: -10px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: -5px;
    transform: skewY(2.5deg);

}

.nft-border-transformv2 {
    margin-top: -10px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: -5px;
    transform: skewY(1.5deg);

}

.mt-n-nft-banner {
    margin-top: -10rem !important;
}

@media only screen and (max-width: 600px) {
    .fanzine-football-nav {
        margin-top: -80px !important;
    }

    .mt-n-nft-banner {
        margin-top: -3rem !important;
    }
}

@media only screen and (min-width: 1550px) {
    .nft-imgComponent {
        /*height: 900px !important;*/
    }
}

@media only screen and (min-width: 2150px) {
    .nft-imgComponent {
        /*height: 1080px !important;*/
    }
}

@media only screen and (min-width: 2150px) {
    .nft-imgComponent {
        /*height: 2160px !important;*/
    }
}
